type Props = {};

export const Terms = ({}: Props) => {
  return (
    <div className="container py-20">
      <div className="space-y-6 md:mx-20">
        <div className="mb-14">
          <h1 className="text-4xl font-bold">
            Terms &<span className="ml-2 text-primary">Conditions</span>
          </h1>
          <div className="line h-1 w-40 bg-primary rounded-md mt-2"></div>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Acceptance of Terms
          </h2>
          <p className="text-lg text-suble">
            Welcome to our website and app TeaReceipts. By accessing or using this site/app, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree to these terms, please do not access, or use the site.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Use of the Site
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              The site is a platform for anonymous users that must be at least 16 years of age to share forewarnings and information for the community's benefit.
            </li>
            <li className="text-lg text-suble">
              Users must not use the site for unlawful, harmful, or malicious purposes.
            </li>
            <li className="text-lg text-suble">
              The site is not a substitute for professional advice. Users should verify information and seek professional advice when needed.
            </li>
          </ol>
          <h1 className="text-xl font-normal text-subleHard mb-4 mt-4">
            You agree not to engage in any of the following activities:
          </h1>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              Reverse Engineering: You shall not attempt to reverse engineer, decompile, disassemble, or derive the source code of TeaReceipts or any part thereof, except to the extent expressly permitted by applicable law.
            </li>
            <li className="text-lg text-suble">
              Introduction of Harmful Code: You shall not introduce, transmit, or knowingly allow the introduction or transmission of any harmful code, including but not limited to viruses, malware, spyware, or any other code or software designed to disrupt, disable, harm, or otherwise impair the proper functioning of TeaReceipts or any connected network, server, or system.
            </li>
            <li className="text-lg text-suble">
              Any violation of these prohibitions may result in the termination of your access to TeaReceipts and may be subject to legal consequences. We reserve the right to take appropriate legal action to prevent or remedy any violation of these terms.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Anonymity
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              Users may remain anonymous while using the site.
            </li>
            <li className="text-lg text-suble">
              The site does not guarantee absolute anonymity and users are responsible for safeguarding their personal information.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Content Guidelines
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              Users are solely responsible for the content they post on the site.
            </li>
            <li className="text-lg text-suble">
              Content should be accurate, respectful, and relevant to the community.
            </li>
            <li className="text-lg text-suble">
              Prohibited content includes but is not limited to: hate speech, harassment, illegal activities, and personal attacks.
            </li>
            <li className="text-lg text-suble">
              All posts must be accompanied by a graphic attachment supporting any claims that are being post.
            </li>
            <li className="text-lg text-suble">
              All posts must also protect the party or parties that are being reported against (under the acceptation that they can be revealed under the search page).
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Moderation and Removal of Content
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              The site reserves the right to moderate, edit, or remove content that violates these terms.
            </li>
            <li className="text-lg text-suble">
              The site is not responsible for any loss or damage resulting from the removal of content.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Intellectual Property
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              Users retain ownership of the content they submit to the site.
            </li>
            <li className="text-lg text-suble">
              By submitting content, users grant the site a non-exclusive, royalty-free license to use, modify, and display the content.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Privacy
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              The site respects user privacy and handles personal information in accordance with its Privacy Policy.
            </li>
            <li className="text-lg text-suble">
              Users are responsible for maintaining the confidentiality of their post information.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Limitation of Liability
          </h2>
          <p className="text-lg text-suble pl-4">
            TeaReceipts is provided "as-is" and "as available," without any representation or warranty of any kind, either expressed or implied. By using TeaReceipts, you acknowledge and agree that there are no implied warranties or guarantees concerning the functionality, accuracy, reliability, availability, or performance of the app.
          </p>
          <p className="text-lg text-suble pl-4">
            To the fullest extent permitted by applicable law, we expressly disclaim all warranties, whether statutory, express, or implied, including but not limited to any implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. We do not warrant that TeaReceipts will meet your requirements or that the operation of the app will be uninterrupted, error-free, or secure.
          </p>
          <p className="text-lg text-suble pl-4">
            You use TeaReceipts at your own discretion and risk, and you will be solely responsible for any damage to your device or loss of data that results from the use of the app. No advice or information, whether oral or written, obtained by you from TeaReceipts or through the app shall create any warranty not expressly stated in these Terms and Conditions.
          </p>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Changes to Terms
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              The site reserves the right to update or modify these terms at any time without prior notice.
            </li>
            <li className="text-lg text-suble">
              Continued use of the site after changes constitutes acceptance of the modified terms.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Termination
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              The site reserves the right to terminate or suspend access to the site without notice for violating these terms.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Governing Law
          </h2>
          <ol className="list-decimal ml-5 pl-4 space-y-2">
            <li className="text-lg text-suble">
              These terms are governed by and construed in accordance with the laws of Georgia, USA, without regard to its conflict of law provisions.
            </li>
          </ol>
        </div>

        <div>
          <h2 className="text-xl font-normal text-subleHard mb-4">
            Contact
          </h2>
          <p className="text-lg text-suble pl-4">
            By using this Site, you agree to these terms and conditions. If you have any questions or concerns, please contact us at info@teareceipts.com
          </p>
          <p className="text-lg text-suble pl-4">Revised March July, 2024.</p>
        </div>
      </div>
    </div>
  );
};
