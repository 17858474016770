import { useInfiniteQuery } from "@tanstack/react-query";
import Post from "../../../services/post.service";
import { Post_Endpoints } from "../../../types/endpoint";
import { Comment as CommentI } from "../../../types/post";
import CommentsList from "./commentList";
import { Button } from "../../../components\u0017/ui/button";

interface Props {
  postId: string;
}

interface PaginatedCommentsI {
  pages: {
    data: CommentI[];
    nextPage: number;
  }[];
}

function PaginatedComments({ postId }: Props) {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    error,
  } = useInfiniteQuery({
    queryKey: [Post_Endpoints.GET_COMMENTS, postId],
    queryFn: ({ pageParam = 1 }) =>
      Post.fetchComments({
        pageParam,
        postId,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  console.log(data, "data");

  if (status === "pending") {
    return (
      <div className="container flex justify-between flex-wrap gap-2 px-10">
        Loading...
      </div>
    );
  }

  if (error || data?.pages[0]?.data.length < 1) {
    return (
      <div className="container flex flex-col items-center justify-center py-10">
        No comments on post yet...
      </div>
    );
  }

  console.log(data?.pages[0]?.data);

  return (
    <div className="pb-4">
      <div className="products card-grid">
        <CommentsList pages={data?.pages} postId={postId}/>
      </div>
      <div className="flex justify-center pt-4">
        <Button
          variant="link"
          className="hover:bg-primary-hover"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage ? "Loading..." : !hasNextPage ? "No more comments to load" : "Load More"}
        </Button>
      </div>
    </div>
  );
}

export default PaginatedComments;
