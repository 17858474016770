import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Loader2 } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { signupFormSchema } from "../schema/zod"; // Create a schema for signup
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import AuthWrapper from "../components\u0017/custom/auth";
import { useSignupMutation } from "../services/hooks/auth";
import { Checkbox } from "../components\u0017/ui/checkbox";

type FormSchemaType = z.infer<typeof signupFormSchema>;

export const SignupPage = () => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(signupFormSchema),
    shouldFocusError: true,
    defaultValues: {
      age: 16,
    },
  });

  const {
    mutation: { mutate },
    formStatus: { setFormLoading, isFormLoading },
  } = useSignupMutation();

  const onSubmit = async (values: FormSchemaType) => {
    setFormLoading(true);
    console.log(values, "values");
    mutate({
      email: values.email,
      password: values.password,
      age: values.age,
    });
  };

  return (
    <AuthWrapper>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex h-screen w-screen items-center justify-center space-y-6"
        >
          <Card className="w-[350px] md:w-[400px] border-none shadow-none">
            <CardHeader className="space-y-1">
              <CardTitle className="text-center text-2xl">Sign Up</CardTitle>
              <CardDescription className="text-center">
                Create your account by filling in the details below
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              {/* <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Name
                    </FormLabel>
                    <FormControl>
                      <Input className="mt-2" placeholder="name" {...field} />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              /> */}
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input className="mt-2" placeholder="email" {...field} />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Password
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="mt-2"
                        placeholder="password"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Confirm Password
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="mt-2"
                        placeholder="confirm password"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="age"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Age
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="mt-2"
                        placeholder="age"
                        type="number"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="terms"
                render={({ field }) => (
                  <FormItem className="flex items-center">
                    <div className="flex items-start">
                      <FormControl>
                        <Checkbox
                          className="mt-2"
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <div className="flex flex-col">
                        <FormLabel className="ml-2 mt-[4px] text-md font-normal text-black dark:text-white">
                          I agree to the 
                          <Link to='/terms' className="ml-2 underline text-primary" target="_black">
                          terms and conditions
                          </Link>
                        </FormLabel>
                        <FormMessage className="ml-2 mt-2 dark:text-red-800" />
                      </div>
                    </div>
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="flex flex-col">
              <Button
                type="submit"
                disabled={isFormLoading}
                className="w-full text-white"
              >
                {isFormLoading && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Submit
              </Button>
              <div className="mt-4 text-center">
                Already have an account?
                <Link
                  to="/sign-in"
                  className="ml-2 text-sm text-muted-foreground underline hover:text-primary"
                >
                  Sign In
                </Link>
              </div>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </AuthWrapper>
  );
};

export default SignupPage;
