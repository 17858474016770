import { Button } from "../../../components\u0017/ui/button";
import { Forward, MessageCircle, ThumbsUp } from "lucide-react";
import { PostI } from "../../../types/post";
import { useEngagement, useLikePost } from "../../../services/hooks/post";
import { useAuthentication } from "../../../services/hooks/auth";

type Props = {
  post?: PostI;
};

function PostEngagement({ post }: Props) {
  const { isError, isLoading, data } = useAuthentication();
  const { data: postEngagement } = useEngagement(post?.id as string, {
    enabled: !isLoading && !isError,
  });
  const {
    mutation,
    formStatus: { isFormLoading, setFormLoading },
  } = useLikePost();
  console.log(postEngagement, "data, post engagement");
  console.log(data, "data, post user");

  const likePost = () => {
    setFormLoading(true);
    mutation.mutate(post?.id as string);
  };
  return (
    <div className="flex justify-between my-2">
      <Button
        variant="ghost"
        className="text-suble text-sm md:text-base"
        disabled={isError || isFormLoading}
        onClick={likePost}
      >
        <ThumbsUp className="mr-2 h-3 w-3 md:h-6 md:w-6" />{" "}
        {postEngagement?.likesCount} Likes
      </Button>
      <Button variant="ghost" className="text-suble text-sm md:text-base">
        <MessageCircle className="mr-2 h-3 w-3 md:h-6 md:w-6" />{" "}
        {postEngagement?.commentsCount} Comment
      </Button>
      <Button variant="ghost" className="text-suble text-sm md:text-base">
        <Forward className="mr-2 h-3 w-3 md:h-6 md:w-6" /> Share
      </Button>
    </div>
  );
}

export default PostEngagement;
