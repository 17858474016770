import useDialogStore from "../../store/useDialogStore";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";

export function AskForSignIn() {
  const { isOpen, closeDialog } = useDialogStore((state) => ({
    isOpen: state.signInOpen,
    closeDialog: state.closeDialog,
  }));

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sign-in</AlertDialogTitle>
          <AlertDialogDescription>
            To create comments you need to first signin...
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={closeDialog}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              closeDialog();
              window.location.href = '/sign-in'
            }}
          >
            Go to Sign-in page.
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
