import { AskForSignIn } from './askForSignIn'

type Props = {}

function Dialogs({}: Props) {
  return (
    <AskForSignIn/>
  )
}

export default Dialogs