"use client";

import { cn } from "../../../lib/utils";
import Logo from "../../../assets/public/logo.png";
import data from "../../../store/data.json";
import { ChevronDown, Loader2, LogOut } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "../../ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui/alert-dialog";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthentication, useLogout } from "../../../services/hooks/auth";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { isError, data  } = useAuthentication();

  console.log(data?.user, "user");
  const {
    mutation,
    formStatus: { isFormLoading, setFormLoading },
  } = useLogout();
  console.log(isError, "isError");

  return (
    <div className="bg-white h-[80px]">
      <nav className="container flex justify-between border-red-2 py-6">
        <div className="flex navbar-brand items-center w-96">
          <Link to="/teareceipts">
            <img src={Logo} alt="" className="h-10" />
          </Link>
        </div>
        <div className="navbar-links w-full flex justify-end md:px-0">
          <ul className="flex items-center justify-between  md:justify-end space-x-10 text-subtle font-medium">
            {/* {GetNavLinks()} */}
            <li className="lg:pl-44">
              <DropdownMenu>
                <DropdownMenuTrigger className="flex space-x-4 items-center">
                  <li>
                    <Avatar>
                      {/* <AvatarImage
                        src={"/placeholder.jpeg"}
                        alt="userProfile"
                      /> */}
                      {/* {!isError ? (
                        <AvatarImage src="https://github.com/shadcn.png" />
                      ) : ( */}
                        <AvatarFallback>X</AvatarFallback>
                      {/* )} */}
                    </Avatar>
                  </li>
                  <div className="text-left hidden md:inline">
                    <p className="font-bold text-black text-sm capitalize">
                      {data?.user?.username}
                    </p>
                  </div>
                  <ChevronDown color="black" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-52">
                  <DropdownMenuItem className="hover:cursor-pointer">
                    Help & Support
                  </DropdownMenuItem>
                  {!isError ? (
                    <DropdownMenuItem
                      className="hover:cursor-pointer"
                      onClick={() => setIsOpen(true)}
                    >
                      Logout
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem
                      className="hover:cursor-pointer"
                      onClick={() => navigate("/sign-in")}
                    >
                      Login
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </li>
          </ul>
        </div>
      </nav>
      <AlertDialog open={isOpen}>
        <AlertDialogContent className="p-6">
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center flex-col">
              <LogOut color="#008080" size={48} className="mb-4" />
              Are you Sure You want to Logout?
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-10">
            <AlertDialogCancel
              className="w-full"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="w-full"
              onClick={() => {
                setFormLoading(true);
                mutation.mutate();
              }}
              disabled={isFormLoading}
            >
              {isFormLoading && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Navbar;

// type icons = "FAQ" | "Terms & conditions" | "Contact us";

// function getNavIcons(isActive: boolean, name: icons) {
//   const color = isActive ? "#008080" : "gray";
//   const icons = {
//     FAQ: <UserCog color={color} />,
//     "Terms & conditions": <LayoutDashboard color={color} />,
//     "Contact us": <Gem color={color} />,
//   };
//   return icons[name];
// }

const GetNavLinks = () => {
  const location = useLocation();
  return data.navLinks.map(({ path, name }) => {
    const isActive = path === location.pathname;
    return (
      <li key={path} className="flex space-x-2">
        <Link
          to={"/teareceipts"}
          className={cn("flex space-x-2", {
            "navbar-item-hover": !isActive,
            "nav-active-item": isActive,
          })}
        >
          {/* <span>{getNavIcons(isActive, name as icons)}</span> */}
          <span className="hidden sm:inline whitespace-nowrap text-sm">
            {name}
          </span>
        </Link>
      </li>
    );
  });
};
