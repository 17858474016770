import Heading from "../components/custom/heading";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { MailOpen, MapPin, Phone } from "lucide-react";

interface props {}

export const Contact = ({}: props) => {
  return (
    <div className="container py-20 px-10">
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1  space-y-8">
          <div>
            <h1 className="text-4xl font-bold">
              Get in<span className="ml-2 text-primary">Touch</span>
            </h1>
            <div className="line h-1 w-32 bg-primary rounded-md mt-2"></div>
          </div>
          <p className="text-suble">
            TeaReceipts is open to customer feedback and potential investors.
          </p>
          <Input
            className="rounded-lg p-6 px-4 placeholder:text-primary placeholder:text-lg placeholder:font-normal"
            placeholder="Full Name"
          ></Input>
          <Input
            className="rounded-lg p-6 px-4 placeholder:text-primary placeholder:text-lg placeholder:font-normal"
            placeholder="Your Email"
            type="email"
          ></Input>
          <Textarea
            className="rounded-lg p-5 px-4 placeholder:text-primary placeholder:text-lg placeholder:font-normal"
            placeholder="Message"
            rows={6}
          ></Textarea>
          <Button className="w-full text-lg">Submit</Button>
        </div>
        <div className="relative flex-1 flex items-center justify-center">
          <div className="hidden lg:block card bg-primary absolute w-60 h-96 top-0 right-0"></div>
          <div className="iframe z-10 pt-10 ml-8 hidden lg:block">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.513727033824!2d-84.36316632347726!3d33.8506490283469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50f56bef8b253%3A0x351af677f1a2922d!2s3455%20Peachtree%20Rd%20NE%20%23500%2C%20Atlanta%2C%20GA%2030326%2C%20USA!5e0!3m2!1sen!2s!4v1720208536318!5m2!1sen!2s"
              style={{ width: "450px", height: "500px" }}
            ></iframe>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:items-center justify-center mt-24">
        <div className="py-4 px-14 flex space-x-4">
          <div>
            <MailOpen size={44} strokeWidth={1.5} />
          </div>
          <div className="space-y-1">
            <h2 className="font-semibold">Email:</h2>
            <p className="text-[14px] font-normal text-subtle">
              info@teareceipts.com
            </p>
          </div>
        </div>
        <div className="py-4 px-14 flex space-x-4">
          <div>
            <MapPin size={44} strokeWidth={1.5} />
          </div>
          <div className="space-y-1">
            <h2 className="font-semibold">Location:</h2>
            <p className="text-[14px] font-normal text-subtle">
              3455 Peachtree Rd NE suite 500, Atlanta, GA 30326, USA
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
