import { create } from "zustand";

type StoreState = {
  signInOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
};

const useDialogStore = create<StoreState>((set, get) => ({
  signInOpen: false,
  openDialog: () => set({ signInOpen: true }),
  closeDialog: () => set({ signInOpen: false }),
}));

export default useDialogStore;
