import { MoreVertical } from "lucide-react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components\u0017/ui/avatar";
import { Button } from "../../../components\u0017/ui/button";
import { CardDescription, CardTitle } from "../../../components\u0017/ui/card";

import { PostI } from "../../../types/post";
type Props = {
  post?: PostI;
};

function PostHeader({ post }: Props) {
  return (
    <>
      <div className="flex justify-between">
        <div className="flex justify-start items-center capitalize">
          <div>
            <Avatar>
              <AvatarImage
                src={"../../../placeholder.jpeg"}
                alt="userProfile"
              />
              {/* <AvatarImage src="https://github.com/shadcn.png" /> */}
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
          </div>
          <div className="ml-4">
            <CardTitle className="text-lg font-semibold">
              {post?.user?.username}
            </CardTitle>
          </div>
        </div>
        <div>
          <Button variant="ghost" size="icon">
            <MoreVertical className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <CardDescription className="pt-2 md:pt-4 text-sm md:text-base">
        {post?.caption}
      </CardDescription>
    </>
  );
}

export default PostHeader;
