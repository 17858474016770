"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../components/ui/input-otp";
import { useOtp } from "../services/hooks/auth";
import { Loader2 } from "lucide-react";

const FormSchema = z.object({
  pin: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});

export function OtpVerification() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      pin: "",
    },
  });

  const {
    mutation: { mutate },
    formStatus: { isFormLoading, setFormLoading },
  } = useOtp();

  function onSubmit(data: z.infer<typeof FormSchema>) {
    setFormLoading(true);
    mutate({ otp: data.pin });
  }

  return (
    <div className="container flex justify-center mt-36 w-full">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-6 py-6 px-10 border-[1px] shadow-sm rounded-lg"
        >
          <FormField
            control={form.control}
            name="pin"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg">
                  Please enter the one-time password sent to your email.
                </FormLabel>
                <FormControl>
                  <InputOTP maxLength={6} {...field}>
                    <InputOTPGroup className="my-10 w-full">
                      <InputOTPSlot className="w-full h-12 md:w-20 md:h-20 text-xl" index={0} />
                      <InputOTPSlot className="w-full h-12 md:w-20 md:h-20 text-xl" index={1} />
                      <InputOTPSlot className="w-full h-12 md:w-20 md:h-20 text-xl" index={2} />
                    </InputOTPGroup>
                    <InputOTPSeparator />
                    <InputOTPGroup className="my-10 w-full">
                      <InputOTPSlot className="w-full h-12 md:w-20 md:h-20 text-xl" index={3} />
                      <InputOTPSlot className="w-full h-12 md:w-20 md:h-20 text-xl" index={4} />
                      <InputOTPSlot className="w-full h-12 md:w-20 md:h-20 text-xl" index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                {/* <FormDescription>
                  Please enter the one-time password sent to your email.
                </FormDescription> */}
                {/* <FormMessage /> */}
              </FormItem>
            )}
          />

          <Button type="submit" className="w-full" disabled={isFormLoading}>
            {isFormLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            Verify your email address
          </Button>
        </form>
      </Form>
    </div>
  );
}

export default OtpVerification;
