import { Auth_Endpoints } from "../types/endpoint";
import Instance from "./api";

export default class Otp {
  private static Otp = "/otp";

  static verifyOtpHandler(values: { otp: string }) {
    return Instance.put(`${Otp.Otp}/${Auth_Endpoints.VERIFY_OTP}`, values);
  }
}
