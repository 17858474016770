import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
// import { Separator } from "../components/ui/separator";s
import { Loader2 } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { signInFormSchema } from "../schema/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components\u0017/ui/form";
import { Input } from "../components\u0017/ui/input";
import AuthWrapper from "../components\u0017/custom/auth";
import { Link } from "react-router-dom";
import { useSignInMutation } from "../services/hooks/auth";

type FormSchemaType = z.infer<typeof signInFormSchema>;

export const SigninPage = () => {
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(signInFormSchema),
    shouldFocusError: true,
  });

  const {
    mutation: { mutate },
    formStatus: { setFormLoading, isFormLoading },
  } = useSignInMutation();

  const onSubmit = async (values: FormSchemaType) => {
    setFormLoading(true)
    console.log(values, "values");
    mutate(values);
  };

  return (
    <AuthWrapper>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex h-screen w-screen items-center justify-center space-y-6"
        >
          <Card className="w-[350px] md:w-[400px] border-none shadow-none">
            <CardHeader className="space-y-1">
              <CardTitle className="text-center text-2xl">Sign in</CardTitle>
              <CardDescription className="text-center">
                Enter your email and password to login
              </CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input className="mt-2" placeholder="email" {...field} />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel className="text-md font-normal text-black dark:text-white">
                      Password
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="mt-2"
                        placeholder="password"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage className="mt-2 dark:text-red-800" />
                  </FormItem>
                )}
              />
            </CardContent>
            <CardFooter className="flex flex-col">
              <Button
                disabled={isFormLoading}
                type="submit"
                className="w-full text-white"
              >
                {isFormLoading && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Submit
              </Button>
              <div className="mt-4 text-center">
                Don't have an account?
                <Link
                  to="/sign-up"
                  className="ml-2 text-sm text-muted-foreground underline hover:text-primary"
                >
                  Sign Up
                </Link>
              </div>
            </CardFooter>
          </Card>
        </form>
      </Form>
    </AuthWrapper>
  );
};

export default SigninPage;
