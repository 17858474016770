import { create } from "zustand";

type StoreState = {
  parentId: string | null;
  setParentId: (id: string) => void;
};

const useStore = create<StoreState>((set, get) => ({
  parentId: null,
  setParentId: (parentId) => {
    set(() => ({ parentId }));
  },
}));

export default useStore;
