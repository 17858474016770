import {
  Carousel,
  CarouselContent,
  CarouselItem,
  // CarouselNext,
  // CarouselPrevious,
} from "../../../components\u0017/ui/carousel";
import { Separator } from "../../../components\u0017/ui/separator";
import { cn } from "../../../lib/utils";
import { PostI } from "../../../types/post";
import PostAddComments from "./postAddComments";
import PostComments from "./postComments";
import PostEngament from "./postEngagement";

type Props = {
  post?: PostI;
};

function PostContent({ post }: Props) {
  return (
    <>
      <Carousel className="w-full h-56 md:h-[500px]">
        <CarouselContent>
          {post?.image.map((image, index) => (
            <CarouselItem key={index}>
              <div className="h-56 md:h-[500px]">
                <img
                  src={image.presignedUrl}
                  alt={image.path}
                  className={cn("w-full h-full object-cover rounded-lg")}
                />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        {/* <CarouselPrevious />
            <CarouselNext /> */}
      </Carousel>
      {/* <Separator className="mt-4"></Separator> */}
      <div className="mt-4"></div>
      <PostEngament post={post} />
      <Separator></Separator>
      <PostAddComments
        postId={post?.id}
        isTopLevel={true}
        hasSubmit={() => {}}
      />
      <PostComments postId={post?.id as string} />
    </>
  );
}

export default PostContent;
