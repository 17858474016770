import { Auth_Endpoints } from "../types/endpoint";
import Instance from "./api";

interface SignUpI {
  email: string;
  password: string;
  age: Number;
}

interface SignInI {
  email: string;
  password: string;
}

export default class Auth {
  private static authBaseUrl = "/auth";

  static loginHandler(values: SignInI) {
    return Instance.post(`${Auth.authBaseUrl}/${Auth_Endpoints.SIGNIN}`, values);
  }

  static signUpHandler(values: SignUpI) {
    return Instance.post(`${Auth.authBaseUrl}/${Auth_Endpoints.SIGNUP}`, values);
  }

  static logoutHandler() {
    return Instance.get(`${Auth.authBaseUrl}/${Auth_Endpoints.LOGOUT}`);
  }

  static isAuthenticated() {
    return Instance.get(`${Auth.authBaseUrl}/${Auth_Endpoints.IS_AUTHENTICATED}`);
  }
}
