import { Outlet } from "react-router-dom";
import Navbar from "../components\u0017/custom/teareceipts/navbar";
import { Separator } from "../components\u0017/ui/separator";

type Props = {};

function DashboardLayout({}: Props) {
  return (
    <div>
      <Navbar />
      <Separator />
      <div>
        <Outlet></Outlet>
      </div>
    </div>
  );
}

export default DashboardLayout;
