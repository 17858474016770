import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/public/logo.png";
import { Button } from "../ui/button";
import data from "../../store/data.json";
import { cn } from "../../lib/utils";

type Props = {};

const Navbar = ({}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const isPrimary = location.pathname === "/";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container">
      <nav
        className={`flex items-center justify-between p-4 border-b-2 ${
          isPrimary ? "border-primary" : "border-secondary"
        }`}
      >
        <div className="flex items-center">
          <Link to="/">
          <img src={logo} alt="TeaReceipts Logo" className="h-16 w-52" />
          </Link>
        </div>
        <div className="hidden md:flex space-x-6 xl:space-x-16">
          {data.navLinks.map((link) => (
            <NavLink
              key={link.path}
              to={link.path}
              className={({ isActive }) =>
                `${
                  isActive
                    ? "border-b-2 border-primary text-primary"
                    : "text-subtle"
                } ${isPrimary && "text-white"}`
              }
            >
              {link.name}
            </NavLink>
          ))}
        </div>
        <Link to="sign-in" className="hidden md:block">
          <Button
            className={cn(`px-10`, {
              "bg-white text-primary hover:bg-slate-200": isPrimary,
            })}
          >
            Sign in
          </Button>
        </Link>
        <button
          className={cn("md:hidden text-primary", {
            "text-white": isPrimary,
          })}
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </nav>
      <div
        className={`fixed inset-0 bg-white z-50 transform transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden flex flex-col items-center justify-center space-y-10`}
      >
        <button
          className="absolute top-10 right-10 text-primary"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        {data.navLinks.map((link) => (
          <NavLink
            key={link.path}
            to={link.path}
            className={({ isActive }) =>
              `${
                isActive
                  ? "border-b-2 border-primary text-primary"
                  : "text-subtle"
              }`
            }
            onClick={() => setIsMenuOpen(false)}
          >
            {link.name}
          </NavLink>
        ))}
        <Link to="sign-in" onClick={() => setIsMenuOpen(false)}>
          <Button
            className={`px-10 text-white`}
          >
            Sign in
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
