import { Outlet, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../services/hooks/auth";

const ProtectedRoute = () => {
  const navigate = useNavigate();

  const { isLoading, isFetching, data, isError } = useAuthentication();

  if (isLoading || isFetching) {
    return <div className="container">Loading...</div>;
  }

  console.log(isError,"isError")
  if(isError){
    navigate("/sign-in");
  }
  
  if (data?.isVerified === false) {
    navigate("/otp-verification");
  }
  
  return <Outlet />;
};

export default ProtectedRoute;
