import React from "react";
import Comment from "./comment";
import { Comment as CommentI } from "../../../types/post";

interface PaginatedCommentsI {
  pages: {
    data: CommentI[];
    nextPage?: number;
  }[];
  postId?:string
}

const CommentsList = ({ pages, postId }: PaginatedCommentsI) => {
  return (
    <>
      {pages?.map((page, pageIndex) => (
        <React.Fragment key={pageIndex}>
          {page.data.map((item: CommentI) => (
            <Comment key={item.id} comment={item} postId={postId} top/>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};

export default CommentsList;
