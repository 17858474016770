import { Separator } from "../ui/separator";
import { Facebook, Instagram, Youtube } from "lucide-react";
import data from "../../store/data.json";
import { Link } from "react-router-dom";
import tiktok from "../../assets/public/tiktok.png";
type Props = {};

function Footer({}: Props) {
  return (
    <div className="bg-primary pb-4">
      <div className="container text-white flex flex-col">
        <div className="icons flex space-x-4 pt-8 justify-center">
          <a href="https://www.instagram.com/teareceiptsomg/">
            <Instagram />
          </a>
          <a href="https://www.facebook.com/people/Tea-Receipts/pfbid02Z4oB4A4ga3eEt5TwLqi51opJnMFTSgEkE6yirsnjfg2esSCpTPWJXdE2HqWNYqj9l/">
            <Facebook />
          </a>
          <a href="https://www.youtube.com/@teareceiptsomg">
            <Youtube />
          </a>
          <a href="https://www.tiktok.com/@teareceiptsomg">
            <img src={tiktok} alt="" className="w-7" />
          </a>
        </div>
        <div className="links flex justify-center mt-8 whitespace-nowrap">
          {data.navLinks.map((link) => (
            <span>
              <Link key={link.path} to={link.path}>
                {link.name}
              </Link>
              <span className="mx-2 md:mx-4">|</span>
            </span>
          ))}
        </div>
        <Separator className="h-[1px] bg-white my-4"></Separator>
        <div className="rights text-center">
          © 2024 TeaReceipts | All Rights Reserved
        </div>
      </div>
    </div>
  );
}

export default Footer;
