import authSvg from "../../assets/public/signin.svg";

type props = {
  children: React.ReactNode;
};

export const AuthWrapper = ({ children }: props) => {
  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="hidden bg-muted lg:flex bg-[#597C64] justify-center items-center">
        <img
          src={authSvg}
          alt="tea banner"
          className="object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
      <div className="flex items-center justify-center">{children}</div>
    </div>
  );
};

export default AuthWrapper;
