import { CornerDownRight } from "lucide-react";
import { Comment as CommentI } from "../../../types/post";
import { Avatar, AvatarFallback } from "../../../components\u0017/ui/avatar";
import { Button } from "../../../components\u0017/ui/button";
import { cn, getRandomProfile, timeAgo } from "../../../lib/utils";
import React, { useState } from "react";
import PostAddComments from "./postAddComments";
import useStore from "../../../store/store";
import { AvatarImage } from "@radix-ui/react-avatar";

interface Props {
  comment: CommentI;
  postId?: string;
  top?: boolean;
}

const Comment = ({ comment, postId, top }: Props) => {
  const [replyState, setReplyState] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const setParentId = useStore((state) => state.setParentId);

  return (
    <div className="w-full">
      <div className="rounded-2xl flex mt-2 md:mt-4 gap-4 w-full">
        <div className="flex flex-col justify-center items-center">
          <Avatar className="w-8 h-8 md:w-10 md:h-10">
            <AvatarImage src={getRandomProfile()} className="bg-gray-200" />
            <AvatarFallback>TR</AvatarFallback>
          </Avatar>
          <div
            className={cn(
              "md:block border-l-2 border-primary-subtle h-full w-2 mt-2",
              {
                "border-none": !comment?.children?.length,
              }
            )}
          ></div>
        </div>
        <div className="w-full">
          <div className="p-2 pl-4 rounded-2xl bg-gray-100">
            <div className="text-primary capitalize text-sm md:text-base">
              {comment?.user?.username}
            </div>
            <div className="text-subleHard text-xs md:text-sm">
              {comment?.message}
            </div>
          </div>
          <div className="mt-2 text-suble">
            {!comment.last && (
              <Button
                className="h-6 text-xs md:text:sm"
                variant="link"
                onClick={() => {
                  setReplyState((value) => !value);
                  setParentId(comment.id);
                }}
              >
                {!replyState ? "Reply" : "Cancel"}
              </Button>
            )}
            <span className="text-xs md:text-sm text-suble">
              {timeAgo(comment.created_at)}
            </span>
            {replyState && (
              <PostAddComments
                isTopLevel={false}
                postId={postId}
                hasSubmit={() => setReplyState(false)}
              />
            )}
          </div>
        </div>
      </div>
      <>
        {top && !showMore ? (
          <div className="ml-14">
            <Button
              className="text-xs text-suble"
              variant="link"
              onClick={() => setShowMore(true)}
            >
              Show Replies
            </Button>
          </div>
        ) : (
          <>
            {comment?.children?.map((child) => (
              <div className="ml-3 md:ml-4 flex" key={child.id}>
                <div
                  className={cn(
                    "md:block border-l-2 border-b-2 w-4 md:w-10 h-6 md:h-9 rounded-l-lg rounded-t-none mr-2 border-primary-subtle",
                    {
                      "border-none": !(comment?.children?.length > 0),
                    }
                  )}
                ></div>
                <Comment comment={child} postId={postId} />
              </div>
            ))}
          </>
        )}
      </>
    </div>
  );
};

export default Comment;
