import { useMutation, useQuery } from "@tanstack/react-query";
import userService from "../auth.service";
import { useState } from "react";
import { useToast } from "../../components/ui/use-toast";
import { CustomAxiosError } from "../api";
import { redirect, useNavigate, Router } from "react-router-dom";
import { Auth_Endpoints } from "../../types/endpoint";
import Otp from "../otp.service";
import { User } from "../../types/post";

export function useSignInMutation() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isFormLoading, setFormLoading] = useState(false);

  const mutation = useMutation({
    mutationKey: [Auth_Endpoints.SIGNIN],
    mutationFn: userService.loginHandler,
    onSuccess: () => {
      setFormLoading(false);
      toast({
        title: "Log in Success",
        variant: "default",
      });
      navigate("/teareceipts", {
        replace: true,
      });
    },
    onError: (error: CustomAxiosError) => {
      setFormLoading(false);
      const message = error.response?.data?.message || error.message;
      toast({
        title: message,
        variant: "destructive",
      });
    },
  });
  return {
    mutation,
    formStatus: {
      isFormLoading,
      setFormLoading,
    },
  };
}

export function useSignupMutation() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isFormLoading, setFormLoading] = useState(false);

  const mutation = useMutation({
    mutationKey: [Auth_Endpoints.SIGNUP],
    mutationFn: userService.signUpHandler,
    onSuccess: () => {
      setFormLoading(false);
      toast({
        title: "Log in Success",
        variant: "default",
      });
      navigate("/teareceipts");
    },
    onError: (error: CustomAxiosError) => {
      setFormLoading(false);
      const message = error.response?.data?.message || error.message;
      toast({
        title: message,
        variant: "destructive",
      });
    },
  });
  return {
    mutation,
    formStatus: {
      isFormLoading,
      setFormLoading,
    },
  };
}

export function useLogout() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isFormLoading, setFormLoading] = useState(false);

  const mutation = useMutation({
    mutationKey: [Auth_Endpoints.LOGOUT],
    mutationFn: userService.logoutHandler,
    onSuccess: () => {
      setFormLoading(false);
      toast({
        title: "Logout Successful",
        variant: "default",
      });
      navigate("/sign-in", {
        replace: true,
      });
    },
    onError: (error: CustomAxiosError) => {
      setFormLoading(false);
      const message = error.response?.data?.message || error.message;
      toast({
        title: message,
        variant: "destructive",
      });
    },
  });
  return {
    mutation,
    formStatus: {
      isFormLoading,
      setFormLoading,
    },
  };
}

interface AuthenticationI {
  user: User;
  isVerified: boolean;
}

export function useAuthentication() {
  const query = useQuery<AuthenticationI>({
    queryKey: [Auth_Endpoints.IS_AUTHENTICATED],
    retry: false,
    retryOnMount: true,
    queryFn: async () => {
      const response = await userService.isAuthenticated();
      return response.data;
    },
  });
  return query;
}

export function useOtp() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isFormLoading, setFormLoading] = useState(false);

  const mutation = useMutation({
    mutationKey: [Auth_Endpoints.VERIFY_OTP],
    mutationFn: Otp.verifyOtpHandler,
    onSuccess: () => {
      setFormLoading(false);
      toast({
        title: "Login Successful",
        variant: "default",
      });
      navigate("/teareceipts");
    },
    onError: (error: CustomAxiosError) => {
      setFormLoading(false);
      const message = error.response?.data?.message || error.message;
      toast({
        title: message,
        variant: "destructive",
      });
    },
  });
  return {
    mutation,
    formStatus: {
      isFormLoading,
      setFormLoading,
    },
  };
}
