import { useMutation, useQuery } from "@tanstack/react-query";
import postService from "../post.service";
import { Post_Endpoints } from "../../types/endpoint";
import { Engagement, PostI } from "../../types/post";
import { useToast } from "../../components\u0017/ui/use-toast";
import { useState } from "react";
import { CustomAxiosError } from "../api";
import { queryClient } from "../../lib/react-query-provider";
import useDialogStore from "../../store/useDialogStore";
import { ResponseStatus } from "../../types/response.enums";

interface Post {
  post: PostI;
}

export function usePost(id: string) {
  const query = useQuery<Post>({
    queryKey: [Post_Endpoints.FETCH_SINGLE_POST],
    queryFn: async () => {
      const response = await postService.fetchSinglePost(id);
      return response.data;
    },
  });
  return query;
}

export function useEngagement(id: string, options: any) {
  const query = useQuery<Engagement>({
    queryKey: [Post_Endpoints.ENGAGEMENT],
    queryFn: () => postService.fetchPostEngagement(id),
    ...options,
  });
  return query;
}

export function useComment() {
  const { toast } = useToast();
  const [isFormLoading, setFormLoading] = useState(false);
  const openDialog = useDialogStore((state) => state.openDialog);

  const mutation = useMutation({
    mutationKey: [Post_Endpoints.ADD_COMMENT],
    mutationFn: postService.postComment,
    onSuccess: () => {
      setFormLoading(false);
      toast({
        title: "comment Added",
        variant: "default",
      });
      queryClient.invalidateQueries({
        queryKey: [Post_Endpoints.GET_COMMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [Post_Endpoints.ENGAGEMENT],
      });
    },
    onError: (error: CustomAxiosError) => {
      setFormLoading(false);

      if (error.response?.status === ResponseStatus.Unauthorized) {
        openDialog();
        return;
      }

      const message = error.response?.data?.message || error.message;
      toast({
        title: message,
        variant: "destructive",
      });
    },
  });
  return {
    mutation,
    formStatus: {
      isFormLoading,
      setFormLoading,
    },
  };
}

export function useLikePost() {
  const { toast } = useToast();
  const [isFormLoading, setFormLoading] = useState(false);

  const mutation = useMutation({
    mutationKey: [Post_Endpoints.LIKE],
    mutationFn: postService.addPostLike,
    onSuccess: (data) => {
      setFormLoading(false);
      toast({
        title: `Post ${data?.post?.action}`,
        variant: "default",
      });
      queryClient.invalidateQueries({
        queryKey: [Post_Endpoints.ENGAGEMENT],
      });
    },
    onError: (error: CustomAxiosError) => {
      setFormLoading(false);
      const message = error.response?.data?.message || error.message;
      toast({
        title: message,
        variant: "destructive",
      });
    },
  });
  return {
    mutation,
    formStatus: {
      isFormLoading,
      setFormLoading,
    },
  };
}
