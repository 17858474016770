export enum Auth_Endpoints {
    SIGNUP = 'signup',
    SIGNIN = 'signin',
    LOGOUT = 'logout',
    IS_AUTHENTICATED = 'isauthenticated',
    VERIFY_OTP = 'verify-otp',
}
export enum Post_Endpoints {
    FETCH_SINGLE_POST = 'fetch-single-post',
    ADD_COMMENT = 'comment',
    GET_COMMENTS = 'comments',
    ENGAGEMENT = 'engagement',
    LIKE = 'like-post',
}