import { Button } from "../../../components\u0017/ui/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components\u0017/ui/avatar";
import { SendHorizonal } from "lucide-react";
import { Input } from "../../../components\u0017/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components\u0017/ui/form";
import { commentSchema } from "../../../schema/zod";
import { z } from "zod";
import { cn } from "../../../lib/utils";
import { useComment } from "../../../services/hooks/post";
import useStore from "../../../store/store";

type Props = {
  postId?: string;
  isTopLevel: boolean;
  hasSubmit: () => void;
};

type FormSchemaType = z.infer<typeof commentSchema>;

function PostAddComments({ postId, isTopLevel, hasSubmit }: Props) {
  const parentId = useStore((state) => state.parentId);

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(commentSchema),
    shouldFocusError: true,
  });

  const {
    mutation: { mutate },
    formStatus: { setFormLoading, isFormLoading },
  } = useComment();

  const onSubmit = async (values: FormSchemaType) => {
    mutate({
      message: values.comment,
      postId: postId as string,
      parentId: isTopLevel ? null : parentId,
    });
    hasSubmit()
  };

  const avatarUrl = `https://api.dicebear.com/9.x/notionists/svg?seed=Trouble`;

  return (
    <div className="flex my-6 w-full justify-between">
      <div className="mr-4">
        <Avatar>
          <AvatarImage src={avatarUrl} className="bg-gray-200"/>
          <AvatarFallback>CN</AvatarFallback>
        </Avatar>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex w-full">
          <div className="w-full">
            <FormField
              control={form.control}
              name="comment"
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormControl>
                    <Input
                      {...field}
                      className={cn(
                        "bg-gray-100 w-full rounded-2xl text-sm md:text-base outline-none ring-0",
                        {
                          "border-2 border-red-500":
                            form.formState.errors.comment,
                        }
                      )}
                      placeholder="Write your comment here"
                    ></Input>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="ml-2">
            <Button
              variant="ghost"
              className="text-base text-primary"
              type="submit"
            >
              <SendHorizonal className="mr-2 h-6 w-6" />
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

export default PostAddComments;
